// export const URL_LOGIN = "https://apimedidores.apidev.info/signin/"
// export const URL_FORGET = "https://apimedidores.apidev.info/forgetpassword/"


/**
 * FROM LOCAL SERVER
 */
// export const URL_LOGIN = "http://localhost:4088/signin/"
// export const URL_SRV = "http://localhost:4088/app-medidor/"
// export const URL_FORGET = "http://localhost:4088/forgetpassword/"
// export const URL_GETPLANTA = "http://localhost:4088/app-medidor/getlistaempresas"
// export const URL_CTRL_HIDRIC = "http://localhost:4088/ctrl-hidrico/"
// export const URL_SRV_RAIZ = "http://localhost:4088/"


/**
 * FROM REMOTE APISERVER
 */
export const URL_LOGIN = "https://apimedidores.apidev.info/signin/"
export const URL_SRV = "https://apimedidores.apidev.info/app-medidor/"
export const URL_FORGET = "https://apimedidores.apidev.info/forgetpassword/"
export const URL_GETPLANTA = "https://apimedidores.apidev.info/app-medidor/getlistaempresas"
export const URL_CTRL_HIDRIC = "https://apimedidores.apidev.info/ctrl-hidrico/"
export const URL_SRV_RAIZ = "https://apimedidores.apidev.info/"