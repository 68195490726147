import React, { useEffect, useReducer } from 'react'
import { URL_LOGIN } from '../../router/Url';
import LoginContext from './LoginContext';
import LoginReducer from './LoginReducer'

const InitialState = {
  user: 'NO-USER',
  planta: 'NO-PLANTA',
  token: 'NO-TOKEN',
  loginDate: ''
}
const LoginState = (props) => {
  const [state, dispatch] = useReducer(LoginReducer, InitialState);

  useEffect(() => {
      let user = sessionStorage.getItem('user')
      let token = sessionStorage.getItem('token')
      let planta = sessionStorage.getItem('planta')
      let loginDate = sessionStorage.getItem('loginDate')
      if(user && token && planta){
        dispatch({
          type: 'SetLogin',
          payload: {
            user: user,
            planta: planta,
            token: token,
            loginDate: loginDate
          }
        })
      }
  }, [])
  

  // console.log('reload loginstate')

  const SetLogin = async ({valores}) => {

    return new Promise(async (resolve, reject) => {
      let query = await fetch(URL_LOGIN,{
        method: 'POST',
        headers: {
          'authorization': "marcuxo",
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user:valores.user,
          clave:valores.password
        })
      })
      let responsito = await query.json()
      if(responsito.data.success){
        let in_login = new Date().toLocaleString()
        sessionStorage.setItem('user',responsito.data.nombre)
        sessionStorage.setItem('planta',responsito.data.empresa)
        sessionStorage.setItem('token',responsito.data.token)
        sessionStorage.setItem('loginDate',in_login)
        dispatch({
          type: 'SetLogin',
          payload: {
            user: responsito.data.nombre,
            planta: responsito.data.empresa,
            token: responsito.data.token,
            loginDate: in_login
          }
        })
        resolve(responsito.data)     
      }else{
        // responsito.data.error = "Imposible realizar laoperacion en estos momentos, intente nuevamente."
        resolve(responsito.data)  
      }

    })

  }

  const GetLoginSession = async () => {
    return new Promise(async (resolve, reject) => {
      const user = sessionStorage.getItem('user')
      const token = sessionStorage.getItem('token')

      if(user && token) {
       
        dispatch({
          type: 'SetLogin',
          payload: {
            user: user,
            token: token,
            loginDate: new Date().toUTCString()
          }
        })
        resolve(true)

      } else {
        resolve(false)
      }

    })
  }

  const CloseLogin = async () => {
    sessionStorage.clear()
    return (dispatch({
      type: 'SetLogin',
      payload: {
        user: 'NO-USER',
        planta: 'NO-PLANTA',
        token: 'NO-TOKEN',
        loginDate: ''
      }
    }))
  }

  return(
    <LoginContext.Provider
      value={{
        LoginState: state,
        SetLogin,
        CloseLogin,
        GetLoginSession
      }}
    >
      { props.children }
    </LoginContext.Provider>
  )
  
}

export default LoginState
