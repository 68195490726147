import React from 'react'
import { URL_CTRL_HIDRIC } from '../router/Url';

export const AddNuevaData = ({data}) => {
  return new Promise(async (resolve, reject) => {
    let query = await fetch(URL_CTRL_HIDRIC+'savenuevadata',{
      method: 'POST',
      headers: {
        'authorization': "marcuxo",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: data
      })
    })
    let responsito = await query.json();

    if(responsito.success){
      resolve(responsito.success)
    }else{
      resolve(!responsito.success)
    }
  })
}
