import { Button } from '@mui/material';
import React, { useContext, useState } from 'react';
import { HeaderMenu } from '../../components/HeaderMenu';

import { URL_CTRL_HIDRIC } from '../../router/Url';
import LoginContext from '../../context/login_context/LoginContext';
import { ObtainFileData } from '../../helper/ObtainFileData.api';

import AttachmentIcon from '@mui/icons-material/AttachFile';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import { CargaFileArica } from './arica/CargaFileArica';
import { CargaFileOchagavia } from './ochagavia/CargaFileOchagavia';
import { useNavigate } from 'react-router-dom';

export const CargarFile = () => {
  const navigate = useNavigate()
  const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);

  const [File_, setFile_] = useState('');
  const [Archivo, setArchivo] = useState({})
  const [Charging, setCharging] = useState(false)

  const HandlePreloadFile = async (e) => {
    setArchivo(e.target.files[0]);
    setFile_(e.target.value.split('\\').pop());
    // console.log(e.target.files[0])
  }

  const HandleUploadFile = async () => {

    await setCharging(true)
    const formData = new FormData();
    formData.append('ARCHIVO',Archivo);
    const fetc = await fetch(URL_CTRL_HIDRIC+'fromfile', {
      method: 'POST',
      headers: {
        'authorization': LoginState.token
      },
      body: formData
    })

    const recuest = await fetc.json();
    // console.log(recuest.body)
    if(recuest.success){
      setCharging(false)
      alert(`Se Actualizaron ${recuest.body.lineas.actualizar} lineas y agregaron ${recuest.body.lineas.agregar} nuevas lineas de datos CORRECTAMENTE!!`)
      setFile_('')
      setArchivo(null)
      navigate('/datos')
    }else{
      alert('Error al cargar los datos favor intente nuevamente.')
    }
  }

  const ObtainFile = async () => {
    await ObtainFileData(LoginState.planta)
  }

  return (
    <>
      <HeaderMenu Pag_Sel={'carga_archivo'} />
      <div className='container fondo_border_shadow py-5 mt-5'>
        <div className='row text-center'>
          <div className='col-12 col-md-2'></div>
          <div className='col-12 col-md-8'>
            <div className='row'>
              <div className='col-12'>
                <label htmlFor='file' id="secondfile" className={Charging?'btn btn-sm btn-info disabled':'btn btn-sm btn-info'}>{
                  File_===''? 
                  <>Seleccionar archivo <AttachmentIcon /></>
                  :<><Inventory2OutlinedIcon /> {File_}</>
                }</label>
                <input
                  type="file"
                  name="file"
                  id="file"
                  disabled={Charging}
                  className="form-control"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  required
                  onChange={(e)=>HandlePreloadFile(e)}
                />
              </div>
              <div className='col-12'>
                <Button
                  variant='contained'
                  disabled={File_===''?true:false}
                  onClick={()=>HandleUploadFile()}
                >
                  {
                    Charging?'Cargando archivo . . . ..':'Cargar Archivo' 
                  }<FilePresentOutlinedIcon />
                   
                </Button>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-2'>
            <span style={{cursor: 'pointer'}} onClick={()=>ObtainFile()}>
              Descargar Ejemplo
              <BrowserUpdatedIcon />
            </span>
          </div>
          <div className='col-12 pt-3'>
            <small>Nota: los datos son buscados por al fecha para actualizar de lo contrario se agregaran</small>
          </div>
        </div>
      </div>
        {/* {
          !Charging?
          <div className='loaderFullpage'>
              <p className='center'>Cargando . . </p>
          </div>
          :null
        } */}
      
    </>
  )
}
