import React from 'react'

const GetIndicadorEconomico = () => {
  return new Promise(async function(resolve, reject) {
    try {
      const query = await fetch(`https://mindicador.cl/api`)

        const reqkkst = await query.json();
        // console.log('el rutttt=>>>>>>>',reqkkst)
        if(reqkkst){
          resolve(reqkkst)
        }else{
          resolve(null)
        }
      } catch (error) {
        resolve(null)
      }
  })
}

export default GetIndicadorEconomico