import { URL_FORGET } from "../router/Url"

export const ForgetPass = (email) => {
  // console.log(email)
  return new Promise(async (resolve, reject) => {
    let query = await fetch(URL_FORGET,{
      method: 'POST',
      headers: {
        'authorization': "marcuxo",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email:email
      })
    })
    let responsito = await query.json();

    if(responsito.data.success){
      resolve(responsito.data)
    }else{
      resolve(responsito.data)
    }
  })
}
