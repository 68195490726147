import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Box, Button, Drawer, TableCell, TableRow, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { GetDataById } from '../helper/GetDataById.api';
import { SaveToUpdateData } from '../helper/SaveToUpdateData.api';
import GetIndicadorEconomicobyDay from '../helper/GetIndicadorEconomicobyDay.api';

export const EditOldData = ({id,IsReload,setIsReload}) => {
  // console.log(id)
  const [drawSate, setDrawSate] = useState(false);
  const [OldData, setOldData] = useState({})
  const [Loading, setLoading] = useState(false)

  const [UE_, setUE_] = useState(0)
  const [LPOLLO_, setLPOLLO_] = useState(0)
  const [LUE, setLUE] = useState(0)
  const [KGVIVO, setKGVIVO] = useState(0)
  const [TOTAL, setTOTAL] = useState(0)
  const [TOTALPOLLO, setTOTALPOLLO] = useState(0)
  const [TOTALPAVO, setTOTALPAVO] = useState(0)
  const [COSTO, setCOSTO] = useState(0)
  
  const [IndiDia, setIndiDia] = useState({
    "dolar":0,
    "uf":0
  })

  const [LoadIndi, setLoadIndi] = useState(false)

  const toggleDrawer = (open) => async (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if(open){
      GetData_Bi_Id();
    }
    setDrawSate(open);
  };

  const HandleCanselDrawer = async () => {
    setDrawSate(false)
  }

  const GetData_Bi_Id = async () => {
    let data = await GetDataById({id})
    // console.log(data)
    await setOldData(data)
    GetDolarByDay(data.FECHA)
  }

  const GetDolarByDay = async (fecha) => {
    // console.log(fecha)
    const val_dolar = await GetIndicadorEconomicobyDay({'indicador':"dolar",fecha})
    const val_uf = await GetIndicadorEconomicobyDay({'indicador':"uf",fecha})

    setIndiDia({
      dolar:val_dolar,
      uf:val_uf})
  }
  
  const Calculate = async () => {
    let calculo_totalpozo = 0
    let calculo_totalpollo = 0
    let calculo_totalpavo = 0
    let calculo_ue = 0
    let calculo_lue = 0
    let calculo_lpollo = 0
    let calculo_lkgvivo = 0

    calculo_ue = ~~OldData.N_PAVO*4+~~OldData.N_POLLO
    await setUE_(calculo_ue)

    if(TOTAL>0){
      calculo_lue = (TOTAL*1000)/~~UE_
      await setLUE(calculo_lue.toFixed(1))
    }

    if(TOTAL>0){
      calculo_lkgvivo = (TOTAL*1000)/(TOTALPAVO+~~TOTALPOLLO)
      await setKGVIVO(calculo_lkgvivo.toFixed(1))
    }

    if( ~~OldData.POZO_3>0 || ~~OldData.POZO_4>0 || ~~OldData.POZO_5>0 ){
      calculo_totalpozo = ~~OldData.POZO_3+~~OldData.POZO_4+~~OldData.POZO_5
      await setTOTAL(calculo_totalpozo)
    }

    if(~~OldData.N_POLLO>0 && ~~OldData.PESO_POLLO>0){
      calculo_totalpollo = OldData.N_POLLO*OldData.PESO_POLLO
      await setTOTALPOLLO(calculo_totalpollo.toFixed(1))
    }

    if(~~OldData.N_PAVO>0 && ~~OldData.PESO_PAVO>0){
      calculo_totalpavo = OldData.N_PAVO*OldData.PESO_PAVO
      await setTOTALPAVO(calculo_totalpavo.toFixed(1))
    }

    if(~~OldData.N_POLLO>0 && TOTAL>0){
      calculo_lpollo = (~~TOTAL*1000)/OldData.N_POLLO
      await setLPOLLO_(calculo_lpollo.toFixed(1))
    }
    if(~~OldData.AGUA_TRATADA>0){
      let calculo = (0.01116*(~~OldData.AGUA_TRATADA*IndiDia.uf)).toFixed(2)
      await setCOSTO(calculo)
    }
  }

  useEffect(() => {
    Calculate()
  }, [OldData])

  useEffect(() => {
    if(IndiDia.dolar>0 && IndiDia.uf>0){
      // console.log('cargaron los indicadores', IndiDia)
    }
  }, [IndiDia])

  const HandleUpdateOldData = async () => {
    let data = {}
    data['FECHA']             = OldData.FECHA
    data['POZO_3']            = OldData.POZO_3
    data['POZO_4']            = OldData.POZO_4
    data['POZO_5']            = OldData.POZO_5
    data['N_POLLO']           = OldData.N_POLLO
    data['PESO_POLLO']        = OldData.PESO_POLLO
    data['N_PAVO']            = OldData.N_PAVO
    data['PESO_PAVO']         = OldData.PESO_PAVO
    data['PESO_PAVO']         = OldData.PESO_PAVO
    data['AGUA_TRATADA']      = OldData.AGUA_TRATADA
    data['AGUA_ASEO']         = OldData.AGUA_ASEO
    data['TOP_25%_BRASIL']    = OldData['TOP_25%_BRASIL']
    data['L/KG_VIVO']         = KGVIVO
    data['L/POLLO']           = LPOLLO_
    data['L/UE']              = LUE
    data['PESO_TOTAL_PAVO']   = TOTALPAVO
    data['PESO_TOTAL_POLLO']  = TOTALPOLLO
    data['TOTAL']             = TOTAL
    data['UE']                = UE_
    data['COSTO']             = COSTO
    id                        = OldData._id

    // console.log('data here', data)
    let response = await SaveToUpdateData({data, id})
    if(response){
      setIsReload(IsReload+1)
      setDrawSate(false)
    }
  }
  
  if(LoadIndi){
    
  }

  return (
    <>
    <span style={{cursor: 'pointer'}} onClick={toggleDrawer(true)}><EditRoundedIcon color='secondary' /></span>
    {
      OldData.FECHA?
       <Drawer
        anchor={'bottom'}
        open={drawSate}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ width:'auto'}}
          role="presentation"
        >
          <div className='container-fluid pt-3 pb-3'>
            <div className='row'>
              <div className='col-12'>
                <h3>Editar Dato__</h3>
              </div>
              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="FECHA"
                    variant="standard"
                    type={'date'}
                    focused={true}
                    name='FECHA'
                    value={OldData.FECHA?OldData.FECHA.length>11?OldData.FECHA.split('T')[0]:OldData.FECHA:null}
                    onChange={(e)=>setOldData({...OldData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="POZO 3"
                    variant="standard"
                    type={'number'}
                    name='POZO_3'
                    focused
                    value={OldData.POZO_3}
                    onChange={(e)=>setOldData({...OldData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="POZO 4"
                    variant="standard"
                    type={'number'}
                    name='POZO_4'
                    focused
                    value={OldData.POZO_4}
                    onChange={(e)=>setOldData({...OldData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="POZO 5"
                    variant="standard"
                    focused
                    type={'number'}
                    name='POZO_5'
                    value={OldData.POZO_5}
                    onChange={(e)=>setOldData({...OldData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="N°POLLO"
                    variant="standard"
                    focused
                    type={'number'}
                    name='N_POLLO'
                    value={OldData.N_POLLO}
                    onChange={(e)=>setOldData({...OldData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="PESO POLLO"
                    variant="standard"
                    focused
                    type={'number'}
                    name='PESO_POLLO'
                    value={OldData.PESO_POLLO}
                    onChange={(e)=>setOldData({...OldData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="N°PAVO"
                    variant="standard"
                    focused
                    type={'number'}
                    name='N_PAVO'
                    value={OldData.N_PAVO}
                    onChange={(e)=>setOldData({...OldData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="PESO PAVO"
                    variant="standard"
                    focused
                    type={'number'}
                    name='PESO_PAVO'
                    value={OldData.PESO_PAVO}
                    onChange={(e)=>setOldData({...OldData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>

              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="M³ AGUA PTR"
                    variant="standard"
                    focused
                    type={'number'}
                    name='AGUA_TRATADA'
                    value={OldData.AGUA_TRATADA}
                    onChange={(e)=>setOldData({...OldData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="AGUA ASEO"
                    variant="standard"
                    type={'number'}
                    focused
                    name='AGUA_ASEO'
                    value={OldData['AGUA_ASEO']}
                    onChange={(e)=>setOldData({...OldData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-12 pt-2 text-info'>
                <small>Valor Uf: {IndiDia.uf}</small><small className='px-2'></small>
                <small>Valor Dolar: {IndiDia.dolar}</small><small className='px-2'>Valores calculados para la fecha: {OldData.FECHA}</small>
              </div>

              <div className='col-12 pt-5 text-right'>
                <Button variant='contained' color='error' size='small'
                  onClick={()=>{
                    HandleCanselDrawer()
                  }}
                  >Cancelar <CancelOutlinedIcon style={{fontSize:'18',marginLeft: '5px',marginBottom:'5px'}} /></Button>
                  {/* espaciador */}
                <span className='px-2'></span>
                <Button
                  variant='contained'
                  size='small'
                  // disabled={isComplete}
                  onClick={()=>HandleUpdateOldData()}
                >Editar Dato <SaveIcon style={{fontSize:'18',marginLeft: '5px',marginBottom:'5px'}} />
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Drawer>
      :null
    }
      
    </>
  )
}
