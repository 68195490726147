import { URL_CTRL_HIDRIC, URL_SRV, URL_SRV_RAIZ } from '../router/Url';

export const ObtainFileData = (planta) => {
  return new Promise(async (resolve, reject) => {
    let query = await fetch(URL_CTRL_HIDRIC+'makefilewithdataexample',{
      method: 'POST',
      headers: {
        'authorization': "marcuxo",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        empresa: planta
      })
    })
    let responsito = await query.json();
    // console.log(responsito)
    if(responsito.success){
      resolve(responsito.body)

      let url = responsito.body;
      let a = document.createElement("a");
      a.href = URL_SRV_RAIZ+url;//to web add url_server+build/
      // a.href = url;
      a.download = "Control_Hidrico.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();

    }else{
      resolve(responsito)
    }
  })
}
