import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Box, Button, Drawer, TableCell, TableRow, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { GetDataById } from '../helper/GetDataById.api';
import { SaveToUpdateData } from '../helper/SaveToUpdateData.api';
import GetIndicadorEconomicobyDay from '../helper/GetIndicadorEconomicobyDay.api';

export const EditOldDataArica = ({id,IsReload,setIsReload,UF,DOLAR}) => {
   // console.log(id)
   const [drawSate, setDrawSate] = useState(false);
   const [OldData, setOldData] = useState({})
 
   const [TOTALPOLLO, setTOTALPOLLO] = useState(0)
   const [TOTALPAVO, setTOTALPAVO] = useState(0)
   const [UE_, setUE_] = useState(0)
   const [TOTAL, setTOTAL] = useState(0)
   const [LPOLLO_, setLPOLLO_] = useState(0)
   const [LUE, setLUE] = useState(0)
   const [KGVIVO, setKGVIVO] = useState(0)
   const [COSTO, setCOSTO] = useState(0)

   const [IndiDia, setIndiDia] = useState({
    "dolar":0,
    "uf":0
  })

 
   const toggleDrawer = (open) => async (event) => {
     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
       return;
     }
     if(open){
       GetData_Bi_Id();
     }
     setDrawSate(open);
   };
   const HandleCanselDrawer = async () => {
     setDrawSate(false)
   }
 
   const GetData_Bi_Id = async () => {
     let data = await GetDataById({id})
     await setOldData(data)
    GetIndicadorByDay(data.FECHA)
   }
   
   const Calculate = async () => {
     let calculo_ue = 0
     let calculo_lue = 0
     let calculo_lpollo = 0
     let calculo_lkgvivo = 0
     let calculo_totalpollo = 0
     let calculo_totalpavo = 0
     let total_water = 0
 
    calculo_ue = (Number(OldData.N_PAVO)*5)+Number(OldData.N_POLLO)
    await setUE_(calculo_ue)

     if(~~OldData.N_POLLO>0 && ~~OldData.PESO_POLLO>0){
       calculo_totalpollo = Number(OldData.N_POLLO)*Number(OldData.PESO_POLLO)
       await setTOTALPOLLO(calculo_totalpollo.toFixed(1))
     }
 
     if(~~OldData.N_PAVO>0 && ~~OldData.PESO_PAVO>0){
       calculo_totalpavo = OldData.N_PAVO*OldData.PESO_PAVO
       await setTOTALPAVO(calculo_totalpavo.toFixed(1))
     }
 
     if(Number(OldData.TOTAL)>0){
       calculo_lkgvivo = (Number(OldData.TOTAL)*1000)/(Number(OldData.PESO_TOTAL_PAVO)+Number(OldData.PESO_TOTAL_POLLO))
       await setKGVIVO(calculo_lkgvivo.toFixed(1))
     }
 
     if(Number(OldData.N_POLLO)>0 && Number(OldData.TOTAL)>0){
       calculo_lpollo = (Number(OldData.TOTAL)*1000)/Number(OldData.N_POLLO)
       await setLPOLLO_(calculo_lpollo.toFixed(1))
     }
     if(Number(OldData.TOTAL)>0){
       calculo_lue = (Number(OldData.TOTAL)*1000)/UE_
       // console.log('L/UE',UE_)
       await setLUE(calculo_lue.toFixed(1))
     }
     if(Number(OldData.AGUA_TRATADA>0)){
       let calculo = (IndiDia.uf*Number(OldData.AGUA_TRATADA)*(.00431*IndiDia.uf+IndiDia.dolar*0.3332*(1-0.12))/IndiDia.uf).toFixed(0)
       await setCOSTO(calculo)
     }
     total_water = Number(OldData.AGUA_POZO)+Number(OldData.AGUA_POTABLE)+Number(OldData.AGUA_OSMOSIS)
     await setTOTAL(total_water)
   }

   const GetIndicadorByDay = async (fecha) => {
    // console.log(fecha)
    const val_dolar = await GetIndicadorEconomicobyDay({'indicador':"dolar",fecha})
    const val_uf = await GetIndicadorEconomicobyDay({'indicador':"uf",fecha})

    setIndiDia(
      {
        dolar:val_dolar,
        uf:val_uf
      }
    )
  }
 
   useEffect(() => {
     Calculate()
   }, [OldData])
 
   const HandleUpdateOldData = async () => {
     await Calculate()
     let data = {}
     data['FECHA']             = OldData.FECHA
     data['N_POLLO']           = OldData.N_POLLO
     data['PESO_POLLO']        = OldData.PESO_POLLO
     data['N_PAVO']            = OldData.N_PAVO
     data['AGUA_OSMOSIS']      = OldData.AGUA_OSMOSIS
     data['AGUA_POZO']         = OldData.AGUA_POZO
     data['AGUA_POTABLE']      = OldData.AGUA_POTABLE
     data['PESO_PAVO']         = OldData.PESO_PAVO
     data['PROMEDIO_BRASIL']   = OldData.PROMEDIO_BRASIL
     data['AGUA_TRATADA']      = OldData.AGUA_TRATADA
     data['TOP_25%_BRASIL']    = OldData['TOP_25%_BRASIL']
     data['L/KG_VIVO']         = KGVIVO
     data['L/POLLO']           = LPOLLO_
     data['L/UE']              = LUE
     data['TOTAL']             = TOTAL
     data['PESO_TOTAL_PAVO']   = TOTALPAVO
     data['PESO_TOTAL_POLLO']  = TOTALPOLLO
     data['UE']                = UE_
     data['COSTO']             = COSTO
     id                        = OldData._id
 
     // console.log('data here', data)
     let response = await SaveToUpdateData({data, id})
     if(response){
       setIsReload(IsReload+1)
       setDrawSate(false)
     }
   }  

   return (
     <>
     <span style={{cursor: 'pointer'}} onClick={toggleDrawer(true)}><EditRoundedIcon color='secondary' /></span>
     {
       OldData.FECHA?
        <Drawer
         anchor={'bottom'}
         open={drawSate}
         onClose={toggleDrawer(false)}
       >
         <Box
           sx={{ width:'auto'}}
           role="presentation"
         >
           <div className='container-fluid pt-3 pb-3'>
             <div className='row'>
               <div className='col-12'>
                 <h3>Editar Dato__</h3>
               </div>
               <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="FECHA"
                    variant="standard"
                    type={'date'}
                    focused
                    name='FECHA'
                    value={OldData.FECHA}
                    onChange={(e)=>setOldData({...OldData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="AGUA POZO"
                    variant="standard"
                    type={'number'}
                    name='AGUA_POZO'
                    value={OldData.AGUA_POZO}
                    onChange={(e)=>setOldData({...OldData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="AGUA POTABLE"
                    variant="standard"
                    type={'number'}
                    name='AGUA_POTABLE'
                    value={OldData.AGUA_POTABLE}
                    onChange={(e)=>setOldData({...OldData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="AGUA OSMOSIS"
                    variant="standard"
                    type={'number'}
                    name='AGUA_OSMOSIS'
                    value={OldData.AGUA_OSMOSIS}
                    onChange={(e)=>setOldData({...OldData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="N°POLLO"
                    variant="standard"
                    type={'number'}
                    name='N_POLLO'
                    value={OldData.N_POLLO}
                    onChange={(e)=>setOldData({...OldData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="PESO POLLO"
                    variant="standard"
                    type={'number'}
                    name='PESO_POLLO'
                    value={OldData.PESO_POLLO}
                    onChange={(e)=>setOldData({...OldData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              {/* <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="PESO TOTAL POLLO"
                    variant="standard"
                    type={'number'}
                    name='PESO_TOTAL_POLLO'
                    value={OldData.PESO_TOTAL_POLLO}
                    onChange={(e)=>setOldData({...OldData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div> */}
              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="N°GALLINA"
                    variant="standard"
                    type={'number'}
                    name='N_PAVO'
                    value={OldData.N_PAVO}
                    onChange={(e)=>setOldData({...OldData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="PESO GALLINA"
                    variant="standard"
                    type={'number'}
                    name='PESO_PAVO'
                    value={OldData.PESO_PAVO}
                    onChange={(e)=>setOldData({...OldData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="M³ AGUA ASEO"
                    variant="standard"
                    type={'number'}
                    name='AGUA_ASEO'
                    value={OldData.AGUA_ASEO}
                    onChange={(e)=>setOldData({...OldData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>

              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="M³ AGUA PTR"
                    variant="standard"
                    type={'number'}
                    name='AGUA_TRATADA'
                    value={OldData.AGUA_TRATADA}
                    onChange={(e)=>setOldData({...OldData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              {/* <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="25% BRASIL"
                    variant="standard"
                    type={'number'}
                    name='TOP_25%_BRASIL'
                    value={OldData['TOP_25%_BRASIL']}
                    onChange={(e)=>setOldData({...OldData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div> */}
              <div className='col-12 pt-2 text-info'>
                <small>Valor Uf: {IndiDia.uf}</small><small className='px-2'></small>
                <small>Valor Dolar: {IndiDia.dolar}</small><small className='px-2'>Valores calculados para la fecha: {OldData.FECHA}</small>
              </div>
 
               <div className='col-12 pt-5 text-right'>
                 <Button variant='contained' color='error' size='small'
                   onClick={()=>{
                     HandleCanselDrawer()
                   }}
                   >Cancelar <CancelOutlinedIcon style={{fontSize:'18',marginLeft: '5px',marginBottom:'5px'}} /></Button>
                   {/* espaciador */}
                 <span className='px-2'></span>
                 <Button
                   variant='contained'
                   size='small'
                   // disabled={isComplete}
                   onClick={()=>HandleUpdateOldData()}
                 >Editar Dato <SaveIcon style={{fontSize:'18',marginLeft: '5px',marginBottom:'5px'}} />
                 </Button>
               </div>
             </div>
           </div>
         </Box>
       </Drawer>
       :null
     }
       
     </>
   )
 }
 