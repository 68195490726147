import React from 'react'

const GetIndicadorEconomicobyDay = ({indicador, fecha}) => {
  let rev_fecha = fecha.split('-')
  let fecha_rev = rev_fecha[2]+"-"+rev_fecha[1]+"-"+rev_fecha[0]
  // console.log(indicador, rev_fecha, fecha_rev)
  return new Promise(async function(resolve, reject) {
    try {
        const query = await fetch(`https://mindicador.cl/api/${indicador}/${fecha_rev}`)

        const reqkkst = await query.json();
        // console.log('el rutttt=>>>>>>>',reqkkst)
        if(reqkkst){
          resolve(reqkkst.serie[0].valor)
        }else{
          resolve(null)
        }
      } catch (error) {
        resolve(null)
      }
  })
}

export default GetIndicadorEconomicobyDay