import React from 'react'
import { URL_CTRL_HIDRIC } from '../router/Url';

export const EditRefBrasil = (valores) => {
  return new Promise(async (resolve, reject) => {
    let query = await fetch(URL_CTRL_HIDRIC+'edithref_brasil',{
      method: 'POST',
      headers: {
        'authorization': "paico2021",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: valores
      })
    })
    let responsito = await query.json();
    // console.log(responsito.body)
    if(responsito.success){
      resolve(responsito.body)
    }else{
      resolve(responsito.body)
    }
  })
}
