import { Alert, Box, Button, Drawer, TextField } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'

import SaveIcon from '@mui/icons-material/Save';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PinOutlinedIcon from '@mui/icons-material/PinOutlined';
import { AddNuevaData } from '../helper/AddNuevaData.api';
import LoginContext from '../context/login_context/LoginContext';
import { FormatNumeral } from './FormatNumber';
import GetIndicadorEconomicobyDay from '../helper/GetIndicadorEconomicobyDay.api';

export const AddnewDato = ({IsReload,setIsReload,uf,dolar}) => {
  const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
  const [UE_, setUE_] = useState(0)
  const [LPOLLO_, setLPOLLO_] = useState(0)
  const [LUE, setLUE] = useState(0)
  const [KGVIVO, setKGVIVO] = useState(0)
  const [TOTAL, setTOTAL] = useState(0)
  const [TOTALPOLLO, setTOTALPOLLO] = useState(0)
  const [TOTALPAVO, setTOTALPAVO] = useState(0)
  const [COSTO, setCOSTO] = useState(0)
  const [drawSate, setDrawSate] = useState(false);

  const [IndiDia, setIndiDia] = useState({
    "dolar":0,
    "uf":0
  })

  const [NuevaData, setNuevaData] = useState({
    'FECHA':'',
    'POZO_3':'',
    'POZO_4':'',
    'POZO_5':'',
    'N_POLLO':'',
    'PESO_POLLO':'',
    'N_PAVO':'',
    'PESO_PAVO':'',
    'AGUA_TRATADA':'',
    'AGUA_ASEO':''
  })
  const [isComplete, setisComplete] = useState(true)

  const SaveNuevaData = async () => {
    // console.log(NuevaData,'TOTAL',TOTAL,'UE',UE_,'LPOLLO_', LPOLLO_,'LUE',LUE,'KGVIVO',KGVIVO,'TOTALPAVO',TOTALPAVO,'TOTALPOLLO',TOTALPOLLO)
    let data = NuevaData
    data['TOTAL']             = TOTAL
    data['PESO_TOTAL_POLLO']  = TOTALPOLLO
    data['PESO_TOTAL_PAVO']   = TOTALPAVO
    data['UE']                = UE_
    data['L/POLLO']           = LPOLLO_
    data['L/UE']              = LUE
    data['L/KG_VIVO']         = KGVIVO
    data['FECHA']             = data.FECHA
    data['EMPRESA']           = LoginState.planta
    data['COSTO']             = COSTO
    // console.log(data)
    const saver = await AddNuevaData({data})
    if(saver){
      HandleCanselDrawer()
      setIsReload(IsReload+1)
    }
  }

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawSate(open);
  };

  const Calculate = async () => {
    let calculo_totalpozo = 0
    let calculo_totalpollo = 0
    let calculo_totalpavo = 0
    let calculo_ue = 0
    let calculo_lue = 0
    let calculo_lpollo = 0
    let calculo_lkgvivo = 0

    
    calculo_ue = ~~NuevaData.N_PAVO*4+~~NuevaData.N_POLLO
    await setUE_(calculo_ue)

    if(TOTAL>0){
      calculo_lue = (TOTAL*1000)/~~UE_
      await setLUE(calculo_lue.toFixed(1))
    }

    if(TOTAL>0){
      calculo_lkgvivo = (TOTAL*1000)/(TOTALPAVO+~~TOTALPOLLO)
      await setKGVIVO(calculo_lkgvivo.toFixed(1))
    }

    if( ~~NuevaData.POZO_3>0 || ~~NuevaData.POZO_4>0 || ~~NuevaData.POZO_5>0 ){
      calculo_totalpozo = ~~NuevaData.POZO_3+~~NuevaData.POZO_4+~~NuevaData.POZO_5
      await setTOTAL(calculo_totalpozo)
    }

    if(~~NuevaData.N_POLLO>0 && ~~NuevaData.PESO_POLLO>0){
      calculo_totalpollo = NuevaData.N_POLLO*NuevaData.PESO_POLLO
      await setTOTALPOLLO(calculo_totalpollo.toFixed(1))
    }

    if(~~NuevaData.N_PAVO>0 && ~~NuevaData.PESO_PAVO>0){
      calculo_totalpavo = NuevaData.N_PAVO*NuevaData.PESO_PAVO
      await setTOTALPAVO(calculo_totalpavo.toFixed(1))
    }

    if(~~NuevaData.N_POLLO>0 && TOTAL>0){
      calculo_lpollo = (~~TOTAL*1000)/NuevaData.N_POLLO
      await setLPOLLO_(calculo_lpollo.toFixed(1))
    }
    if(~~NuevaData.AGUA_TRATADA>0){
      let calculo = (0.01116*(~~NuevaData.AGUA_TRATADA*IndiDia.uf)).toFixed(1)
      await setCOSTO(calculo)
    }
  }

  const GetIndicadorByDay = async (fecha) => {
    // console.log(fecha)
    const val_dolar = await GetIndicadorEconomicobyDay({'indicador':"dolar",fecha})
    const val_uf = await GetIndicadorEconomicobyDay({'indicador':"uf",fecha})

    setIndiDia(
      {
        dolar:val_dolar,
        uf:val_uf
      }
    )
  }

  useEffect(() => {
    Calculate()
    validateComplete()
  }, [NuevaData])

  const HandleCanselDrawer = async () => {
    setDrawSate(false)
    setNuevaData(
      {
        'FECHA':'',
        'POZO_3':'',
        'POZO_4':'',
        'POZO_5':'',
        'N_POLLO':'',
        'PESO_POLLO':'',
        'N_PAVO':'',
        'PESO_PAVO':'',
        'AGUA_TRATADA':'',
        'AGUA_ASEO':''
      }
    )
    setUE_(0)
    setLPOLLO_(0)
    setLUE(0)
    setKGVIVO(0)
    setTOTAL(0)
    setTOTALPOLLO(0)
    setTOTALPAVO(0)
  }

  const validateComplete = async () => {
    const list_ =  Object.keys(NuevaData)
    setisComplete(false)
    list_.forEach(elmt => {
      if(NuevaData[elmt]===''){
        setisComplete(true)
      }
    });
  }

  useEffect(() => {
    GetIndicadorByDay(NuevaData.FECHA)
  }, [NuevaData.FECHA])
  

  return (
    <>
    <div className='container'>
      <div className='row'>
        <div className='col-12 text-center pb-2'>
          <Button variant='contained' size='small' onClick={toggleDrawer(true)}>
            <PinOutlinedIcon /> <span className='px-1'> </span>Agregar Nuevo Dato
          </Button>
        </div>
      </div>
    </div>
      
      <Drawer
        anchor={'bottom'}
        open={drawSate}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ width:'auto'}}
          role="presentation"
        >
          <div className='container-fluid pt-3 pb-3'>
            <div className='row'>
              <div className='col-12'>
                <h3>Agregar Nuevo Dato__</h3>
              </div>
              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="FECHA"
                    variant="standard"
                    type={'date'}
                    focused
                    name='FECHA'
                    value={NuevaData.FECHA}
                    onChange={(e)=>setNuevaData({...NuevaData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="POZO 3"
                    variant="standard"
                    type={'number'}
                    name='POZO_3'
                    value={NuevaData.POZO_3}
                    onChange={(e)=>setNuevaData({...NuevaData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="POZO 4"
                    variant="standard"
                    type={'number'}
                    name='POZO_4'
                    value={NuevaData.POZO_4}
                    onChange={(e)=>setNuevaData({...NuevaData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="POZO 5"
                    variant="standard"
                    type={'number'}
                    name='POZO_5'
                    value={NuevaData.POZO_5}
                    onChange={(e)=>setNuevaData({...NuevaData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="N°POLLO"
                    variant="standard"
                    type={'number'}
                    name='N_POLLO'
                    value={NuevaData.N_POLLO}
                    onChange={(e)=>setNuevaData({...NuevaData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="PESO POLLO"
                    variant="standard"
                    type={'number'}
                    name='PESO_POLLO'
                    value={NuevaData.PESO_POLLO}
                    onChange={(e)=>setNuevaData({...NuevaData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="N°PAVO"
                    variant="standard"
                    type={'number'}
                    name='N_PAVO'
                    value={NuevaData.N_PAVO}
                    onChange={(e)=>setNuevaData({...NuevaData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="PESO PAVO"
                    variant="standard"
                    type={'number'}
                    name='PESO_PAVO'
                    value={NuevaData.PESO_PAVO}
                    onChange={(e)=>setNuevaData({...NuevaData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>

              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="M³ AGUA PTR"
                    variant="standard"
                    type={'number'}
                    name='AGUA_TRATADA'
                    value={NuevaData.AGUA_TRATADA}
                    onChange={(e)=>setNuevaData({...NuevaData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-6 col-md-2 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="AGUA ASEO"
                    variant="standard"
                    type={'number'}
                    name='AGUA_ASEO'
                    value={NuevaData['AGUA_ASEO']}
                    onChange={(e)=>setNuevaData({...NuevaData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-12 pt-2 text-info'>
                <small>Valor Uf: {IndiDia.uf}</small><small className='px-2'></small>
                <small>Valor Dolar: {IndiDia.dolar}</small><small className='px-2'>Valores calculados para la fecha: {NuevaData.FECHA}</small>
              </div>
              <div className='col-12 text-right pt-2'>
                <small className='text-danger'>Debe completar todos los campos para poder guardar un dato, el campo que no tenga dato rellenelo con un 0</small>
              </div>            

              <div className='col-12 pt-5 text-right'>
                <Button variant='contained' color='error' size='small'
                  onClick={()=>{
                    HandleCanselDrawer()
                  }}
                  >Cancelar <CancelOutlinedIcon style={{fontSize:'18',marginLeft: '5px',marginBottom:'5px'}} /></Button>
                  {/* espaciador */}
                <span className='px-2'></span>
                <Button
                  variant='contained'
                  size='small'
                  disabled={isComplete}
                  onClick={()=>SaveNuevaData()}
                >Agregar Dato <SaveIcon style={{fontSize:'18',marginLeft: '5px',marginBottom:'5px'}} />
                </Button>
              </div>
            </div>
          </div>
        </Box>
      {/* <p onClick={()=>console.log(COSTO)}>click</p> */}
      </Drawer>
    </>
  )
}
