import { Routes,Route, HashRouter } from 'react-router-dom'
import './App.css';
import LoginState from './context/login_context/LoginState';
import { Login } from './pages/login/Login.page';
import CrearCuenta from './pages/makeAccount/CrearCuenta';
import RecoveryPass from './pages/recoveryPass/RecoveryPass';
import { CargarFile } from './pages/session/CargarFile.page';
import { DashBoard } from './pages/session/DashBoard.page';
import { Datos } from './pages/session/Datos.page';
import { ProtectedRoute } from './pages/session/proteccion/ProtectedRoute';

function App() {
  return (
    <LoginState>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/crear_cuenta' element={<CrearCuenta />} />
        <Route path='/recuperar_cuenta' element={<RecoveryPass />} />

        <Route path='/dashboard' element={
          <ProtectedRoute>
            <DashBoard/>
          </ProtectedRoute>
        } />

        <Route path='/carga_archivo' element={
          <ProtectedRoute>
            <CargarFile/>
          </ProtectedRoute>
        } />

        <Route path='/datos' element={
          <ProtectedRoute>
            <Datos/>
          </ProtectedRoute>
        } />
        <Route path='*' element={<Login />} />
      </Routes>
    </LoginState>
  );
}

export default App;
