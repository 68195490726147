import { Skeleton, Stack } from '@mui/material'
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LoginContext from '../../context/login_context/LoginContext'

import './DashBoard.css'
import { HeaderMenu } from '../../components/HeaderMenu'
import { GetLastData } from '../../helper/GetLastData.api'
import { DashBoardArica } from './arica/DashBoardArica'
import { DashBoardPaico } from './paico/DashBoardPaico'
import { DashBoardOchagavia } from './ochagavia/DashBoardOchagavia'

export const DashBoard = () => {

  const navigate = useNavigate();
  const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
  const [LastData_, setLastData_] = useState('')
  const [IsOkPage, setIsOkPage] = useState(false)
  const [Empresa, setEmpresa] = useState('')

  // AGROINDUSTRIAL ARICA S.A.
  // AGROINDUSTRIAL EL PAICO S.A.
  // INDUSTRIAL OCHAGAVIA LTDA

  switch (LoginState.planta) {
    case 'AGROINDUSTRIAL ARICA S.A.':
      return (<DashBoardArica />)

    case 'AGROINDUSTRIAL EL PAICO S.A.':
      return (<DashBoardPaico />)

    case 'INDUSTRIAL OCHAGAVIA LTDA':
      return (<DashBoardOchagavia />)
  }

  // const GetLasInsertData = async () => {
  //   const lastData = await GetLastData(LoginState.planta)
  //   setLastData_(lastData)
  // }

  // useEffect(() => {
  //   GetLasInsertData()
  // }, [])
  
  // useLayoutEffect(() => {
  //   if(LoginState.token!=='NO-TOKEN'){
  //     setEmpresa()
  //     setIsOkPage(true)
  //   }
  //   else {
  //     navigate('/')
  //   }
  // }, [LoginState])

  // if(IsOkPage){

  //   return (
  //     <>
  //       <HeaderMenu Pag_Sel={'dashboard'} />
  //       {/* CONTENIDO PAGINA */}
  //       <div className='container pt-3'>
  //         <div className='row'>
  //           <div className='col'>
  //             <span className='bg-danger lead p-2'
  //               style={{borderRadius:'5px', color: 'yellow', fontWeight: 'unset'}}
  //             > {LastData_?`Fecha Ultimo dato Ingresado ${LastData_}`:"No Hay Datos Ingresados aun 😐"}</span>
  //           </div>
  //         </div>
  //       </div>
  //     </>
  //   )

  // }else{
  //   return (
  //     <Stack spacing={1}>
  //     <br/>
  //       <Skeleton variant="rectangular" height={60} />
  //       <br/>
  //       <Skeleton variant="rounded" height={100} />
  //       <br/>
  //       <Skeleton variant="rounded"  height={50} />
       
  //     </Stack>
  //   )
  // }
  
}
