import React from 'react'
import { URL_CTRL_HIDRIC } from '../router/Url';

export const DeleteDataById = ({id}) => {
  return new Promise(async (resolve, reject) => {
    let query = await fetch(URL_CTRL_HIDRIC+'deletebyid',{
      method: 'POST',
      headers: {
        'authorization': 'marcuxo',
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: id
      })
    })
    let responsito = await query.json();
    if(responsito.success){
      // console.log(responsito)
      resolve(responsito.success)
    }else{
      resolve(responsito.error)
    }
  })
}
