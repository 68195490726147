import React from 'react'
import { URL_SRV } from '../router/Url';

export const GetPlantas = () => {
  return new Promise(async (resolve, reject) => {
    let query = await fetch(URL_SRV+'getlistempresas',{
      method: 'POST',
      headers: {
        'authorization': "paico2021",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    let responsito = await query.json();

    if(responsito.data.success){
      resolve(responsito.data.body)
    }else{
      resolve(responsito.data)
    }
  })
}
