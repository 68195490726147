export const GetRutData = async (rut) => {
  return new Promise(async function(resolve, reject) {
    try {
      const query = await fetch(`https://pasajeros.apidev.info/scan/consulta?rut=${rut}`)

        const reqkkst = await query.json();
        // console.log('el rutttt=>>>>>>>',reqkkst.length)
        if(reqkkst.length){
          resolve(reqkkst)
        }else{
          resolve(null)
        }
      } catch (error) {
        resolve(null)
      }
    

  })
}