import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { HeaderMenu } from '../../components/HeaderMenu'
import { GetData_ExtPozo } from '../../helper/GetData_ExtPozo.api';
import { AddnewDato } from '../../components/AddnewDato';
import { EditOldData } from '../../components/EditOldData';
import { DeleteDataById } from '../../helper/DeleteDataById.api';
import { DatosPageArica } from './arica/DatosPageArica';
import { DatosPageOchagavia } from './ochagavia/DatosPageOchagavia';
import { FormatNumeral } from '../../components/FormatNumber'
import { GetRefBrasil } from '../../helper/GetRefBrasil.api';
import LoginContext from '../../context/login_context/LoginContext';
import GetIndicadorEconomico from '../../helper/GetIndicadorEconomico.api';

import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { EditRef_Brasil } from '../../components/EditRef_Brasil';


export const Datos = () => {
  const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [DataList, setDataList] = useState([])
  const [IsReload, setIsReload] = useState(1)
  const [IndicadorEconomico, setIndicadorEconomico] = useState(null)
  const [IsALLoald, setIsALLoald] = useState(false)
  const [Ref_Brasil, setRef_Brasil] = useState(null)

  const GetDataList = async () => {
    let getList = await GetData_ExtPozo(LoginState.planta)
    setDataList(getList)
    // console.log(getList)
  }

  const GetIdicadorEconomico = async () => {
    const apiIndicadorEconomico = await GetIndicadorEconomico()
    const apiRefBrazil = await GetRefBrasil()
    setRef_Brasil(apiRefBrazil)
    // console.log(apiIndicadorEconomico)
    // console.log('valor dolar',apiIndicadorEconomico.dolar.valor)
    // console.log('valor uf',apiIndicadorEconomico.uf.valor)
    await setIndicadorEconomico(apiIndicadorEconomico)
    await setIsALLoald(true)
  }

  useLayoutEffect(() => {
    GetDataList()
    GetIdicadorEconomico()
  }, [])

  useEffect(() => {
    GetDataList()
  }, [IsReload])
  
  

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - DataList.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const DeleteeDataConfirm = async (id, fecha) => {
    // console.log(id, fecha)
    let seBorra = window.confirm(`Esta seguro de eliminar el dato con fecha ${fecha}`)
    if(seBorra){
      let respuesta = await DeleteDataById({id})
      if(respuesta){
        alert('Data Eliminada')
        setIsReload(IsReload+1)
      }
    }
  }

  switch (LoginState.planta) {
    case 'AGROINDUSTRIAL ARICA S.A.':
      if(IsALLoald){
        return (
          <DatosPageArica
            IndicadorEconomico={IndicadorEconomico}
            Ref_Brasil={Ref_Brasil}
          />
        )
      }else{<></>}
      
    case 'INDUSTRIAL OCHAGAVIA LTDA':
      if(IsALLoald){
        return (
          <DatosPageOchagavia
            IndicadorEconomico={IndicadorEconomico}
            Ref_Brasil={Ref_Brasil}
          />
        )
      }else{<></>}

    case 'AGROINDUSTRIAL EL PAICO S.A.':
      if(IsALLoald){
        return (
                <>
                <HeaderMenu Pag_Sel={'datos'} />
            
                <div className='container'>
                  <div className='row'>
                    <div className='col'>
                      {
                        LoginState.user!=="Cuenta Demo Vista"?(<AddnewDato 
                            IsReload={IsReload} setIsReload={setIsReload}
                            uf={IndicadorEconomico.uf.valor}
                            dolar={IndicadorEconomico.dolar.valor}
                          />):null
                      }
                    </div>
                  </div>
                </div>
            
                <div className='d-flex justify-content-center'>
                  {
                    DataList?
                    <Paper sx={{ width: '98%', height: '75vh', overflow: '' }}>
                    <TableContainer sx={{ maxHeight: '67vh' }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}} size='small'>FECHA</TableCell>
                            <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>POZO 3</TableCell>
                            <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>POZO 4</TableCell>
                            <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>POZO 5</TableCell>
                            <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>TOTAL</TableCell>
                            <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>N° POLLO</TableCell>
                            <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>PESO POLLO</TableCell>
                            <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>TOTAL POLLO</TableCell>
                            <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>N° PAVO</TableCell>
                            <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>PESO PAVO</TableCell>
                            <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>TOTAL PAVO</TableCell>
                            <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>UE</TableCell>
                            <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>L/UE</TableCell>
                            <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>L/POLLO</TableCell>
                            <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>L/KG VIVO</TableCell>
                            <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>M³ AGUA PTR</TableCell>
                            <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>M³ AGUA ASEO</TableCell>
                            <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>COSTO</TableCell>
                            <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}><EditRoundedIcon /></TableCell>
                            <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}><DeleteForeverIcon /></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        {(rowsPerPage > 0
                          ? DataList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          : DataList
                        ).map((row) =>
                          <TableRow
                            key={row._id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            className='hoverTable'
                          >
                            <TableCell style={{textAlign:'center',padding:'2px 0px',border:'1px solid black'}}>{row.FECHA}</TableCell>
                            <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(Number(row.POZO_3).toFixed(1))}</TableCell>
                            <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(Number(row.POZO_4).toFixed(1))}</TableCell>
                            <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(Number(row.POZO_5).toFixed(1))}</TableCell>
                            <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(Number(row.TOTAL).toFixed(1))}</TableCell>
                            <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(Number(row.N_POLLO))}</TableCell>
                            <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(Number(row.PESO_POLLO).toFixed(1))}</TableCell>
                            <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(Number(row.PESO_TOTAL_POLLO).toFixed(1))}</TableCell>
                            <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(Number(row.N_PAVO))}</TableCell>
                            <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(Number(row.PESO_PAVO).toFixed(1))}</TableCell>
                            <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(Number(row.PESO_TOTAL_PAVO).toFixed(1))}</TableCell>
                            <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(Number(row.UE).toFixed(1))}</TableCell>
                            <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(Number(row['L/UE']).toFixed(1))}</TableCell>
                            <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(Number(row['L/POLLO']).toFixed(1))}</TableCell>
                            <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(Number(row['L/KG_VIVO']))}</TableCell>
                            <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(Number(row['AGUA_TRATADA']).toFixed(1))}</TableCell>
                            <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(Number(row['AGUA_ASEO']).toFixed(1))}</TableCell>
                            <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(Number(row['COSTO']).toFixed(1))}</TableCell>
                            <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>
                              {
                                LoginState.user!=="Cuenta Demo Vista"?(<EditOldData id={row._id} IsReload={IsReload} setIsReload={setIsReload} />):null
                              }
                            </TableCell>
                            <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>
                              {
                                LoginState.user!=="Cuenta Demo Vista"?(<span onClick={()=>DeleteeDataConfirm(row._id, new Date(row.FECHA).toISOString().split('T',1))}><DeleteForeverIcon color='error'/></span>):null
                              }
                            </TableCell>
                          </TableRow>
                        )}
            
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[ 25, 50, 75, 100]}
                      component="div"
                      count={DataList.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Paper>
                  :null
                  }
                </div>
                <div className='container-fluid footer-bar mt-2 fondo-header header-text'>
                  <div className='row'>
                    <div className='col-10 col-md-11 small-font text-center pt-1'>
                      Valor UF: $ <b>{IndicadorEconomico?FormatNumeral(IndicadorEconomico.uf.valor):'Cargando'}</b><span className='px-2'></span>
                      Valor DOLAR: <b>US {IndicadorEconomico?FormatNumeral(IndicadorEconomico.dolar.valor):'Cargando'}</b><span className='px-2'></span>
                      Top 25 Brasil: <b>{Ref_Brasil?FormatNumeral(Ref_Brasil.TOP_BRASIL):'Cargando'}</b> L/UE<span className='px-2'></span>
                      Promedio Brasil: <b>{Ref_Brasil?FormatNumeral(Ref_Brasil.PRO_BRASIL):'Cargando'}</b> L/UE<span className='px-3'></span>
                      Valores actualizados <b>{IndicadorEconomico?IndicadorEconomico.fecha.split('T',1):'Cargando'}</b>
                    </div>
                    <div className='col-2 col-md-1 centerAll'>
                      <EditRef_Brasil
                        IsReload={IsReload} setIsReload={setIsReload}
                        uf={IndicadorEconomico.uf.valor}
                        dolar={IndicadorEconomico.dolar.valor}
                        fechaRef={IndicadorEconomico.fecha.split('T',1)}
                        Ref_Brasil={Ref_Brasil}
                        setRef_Brasil={setRef_Brasil}
                      />
                    </div>
                  </div>
                </div>
                      
                </>
              );
      }else{
        <></>
      }
      
  }

  
}

//   const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
//   const [DataList, setDataList] = useState([])

//   const GetDataList = async () => {
//     let getList = await GetData_ExtPozo(LoginState.token)
//     setDataList(getList)
//     console.log(getList)
//   }

//   useEffect(() => {
//     GetDataList()
//   }, [])
  
//   return (
//     <>
//       <HeaderMenu Pag_Sel={'datos'} />
//       <div className='container-fluid'>
//         <div className='row'>
//           <div className='col'>
//             {/* {
//               DataList.map(stp => {
//                 let fecha = new Date(stp.FECHA).toISOString().split('T',1)
//                 return (
//                   <small>{fecha}</small>
//                 )
//               })
//             } */}
//             <TableContainer component={Paper}>
//               <Table sx={{ minWidth: 650 }} size="small" aria-label="a table dense">
//                 <TableHead>
//                   <TableRow>
//                     <TableCell align="right">1</TableCell>
//                     <TableCell align="right">2</TableCell>
//                     <TableCell align="right">3</TableCell>
//                     <TableCell align="right">4</TableCell>
//                     <TableCell align="right">5</TableCell>
//                     <TableCell align="right">6</TableCell>
//                     <TableCell align="right">7</TableCell>
//                     <TableCell align="right">8</TableCell>
//                     <TableCell align="right">9</TableCell>
//                     <TableCell align="right">11</TableCell>
//                     <TableCell align="right">12</TableCell>
//                     <TableCell align="right">13</TableCell>
//                     <TableCell align="right">14</TableCell>
//                     <TableCell align="right">15</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {DataList.map((row) => (
//                     <TableRow
//                       key={row.name}
//                       sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
//                     >
                      
//                       <TableCell align="right">{new Date(row.FECHA).toISOString().split('T',1)}</TableCell>
//                       <TableCell align="right">{row.POZO_3.toFixed(1)}</TableCell>
//                       <TableCell align="right">{row.POZO_4.toFixed(1)}</TableCell>
//                       <TableCell align="right">{row.POZO_5.toFixed(1)}</TableCell>
//                       <TableCell align="right">{row.TOTAL.toFixed(1)}</TableCell>
//                       <TableCell align="right">{row.N_POLLO}</TableCell>
//                       <TableCell align="right">{row.PESO_POLLO.toFixed(1)}</TableCell>
//                       <TableCell align="right">{row.PESO_TOTAL_POLLO.toFixed(1)}</TableCell>
//                       <TableCell align="right">{row.N_PAVO}</TableCell>
//                       <TableCell align="right">{row.PESO_PAVO.toFixed(1)}</TableCell>
//                       <TableCell align="right">{row.PESO_TOTAL_PAVO.toFixed(1)}</TableCell>
//                       <TableCell align="right">{row['L/UE'].toFixed(1)}</TableCell>
//                       <TableCell align="right">{row['L/POLLO'].toFixed(1)}</TableCell>
//                       <TableCell align="right">{row['L/KG_VIVO'].toFixed(1)}</TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }
