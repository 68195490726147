import { Alert, Box, Button, Drawer, TextField } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'

import LoginContext from '../context/login_context/LoginContext';

import SaveIcon from '@mui/icons-material/Save';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import { EditRefBrasil } from '../helper/EditRefBrasil.api';

export const EditRef_Brasil = ({IsReload,setIsReload,uf,dolar,fechaRef,Ref_Brasil,setRef_Brasil}) => {
  
  const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
  const [drawSate, setDrawSate] = useState(false);
  const [thisValues, setThisValues] = useState({
    UF:'',
    DOLAR:'',
    TOP_BRASIL: '',
    PRO_BRASIL: '',
    ID: ''
  })

  const HandleCanselDrawer = async () => {
    setDrawSate(false)
  }

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawSate(open);
  };

  useEffect(() => {
    // console.log('first',uf,dolar,Ref_Brasil)
    setThisValues({
      UF:uf,
      DOLAR:dolar,
      TOP_BRASIL: Ref_Brasil.TOP_BRASIL,
      PRO_BRASIL: Ref_Brasil.PRO_BRASIL,
      ID: Ref_Brasil.ID,
    })
  }, [])
  
  const HandleUpdateREf_brasil = async () => {
    const data = await EditRefBrasil(thisValues)
    // console.log(data)
    setRef_Brasil({
      TOP_BRASIL: data.TOP_BRASIL,
      PRO_BRASIL: data.PRO_BRASIL,
      ID: data.ID,
    })
    HandleCanselDrawer()
  }
  
  return (
    <>
      <SettingsSuggestOutlinedIcon style={{cursor: 'pointer'}} onClick={toggleDrawer(true)} color='action' />
      
      <Drawer
        anchor={'bottom'}
        open={drawSate}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ width:'auto'}}
          role="presentation"
        >
          <div className='container-fluid pt-3 pb-3'>
            <div className='row'>
              <div className='col-12'>
                <h3>Editar Referencias__</h3>
              </div>
              
              <div className='col-6 col-md-3 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="Valor UF"
                    variant="standard"
                    type={'number'}
                    name='POZO_3'
                    helperText={`Modificado ${fechaRef}`}
                    value={thisValues.UF}
                    disabled
                    // onChange={(e)=>setNuevaData({...NuevaData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-6 col-md-3 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="Valor Dolar"
                    variant="standard"
                    type={'number'}
                    disabled
                    name='POZO_3'
                    helperText={`Modificado ${fechaRef}`}
                    value={thisValues.DOLAR}
                    // onChange={(e)=>setNuevaData({...NuevaData,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-6 col-md-3 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="Top 25 Brasil"
                    variant="standard"
                    type={'number'}
                    name='TOP_BRASIL'
                    value={thisValues.TOP_BRASIL}
                    helperText={`Modificado ${Ref_Brasil.FECHA}`}
                    onChange={(e)=>setThisValues({...thisValues,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
              <div className='col-6 col-md-3 pt-2'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <TextField id="input-with-sx" label="Promedio Brasil"
                    variant="standard"
                    type={'number'}
                    name='PRO_BRASIL'
                    value={thisValues.PRO_BRASIL}
                    helperText={`Modificado ${Ref_Brasil.FECHA}`}
                    onChange={(e)=>setThisValues({...thisValues,[e.target.name]:e.target.value})}
                    fullWidth/>
                </Box>
              </div>
             
              <div className='col-12 pt-5 text-right'>
                <Button variant='contained' color='error' size='small'
                  onClick={()=>{
                    HandleCanselDrawer()
                  }}
                  >Cancelar <CancelOutlinedIcon style={{fontSize:'18',marginLeft: '5px',marginBottom:'5px'}} /></Button>
                <span className='px-2'></span>
                <Button
                  variant='contained'
                  size='small'
                  onClick={()=>HandleUpdateREf_brasil()}
                >Editar Referencias <SaveIcon style={{fontSize:'18',marginLeft: '5px',marginBottom:'5px'}} />
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Drawer>
    </>
  )
}
