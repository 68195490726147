import {GetLogin,SetLogin} from '../Types';

export default function (state, action) {
  const { payload, type} = action;

  switch (type) {
    case GetLogin:
      return {
        ...state,
        payload
      }

    case SetLogin:
      return payload
  
    default:
      return state
  }
}