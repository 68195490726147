import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { HeaderMenu } from '../../../components/HeaderMenu'
import LoginContext from '../../../context/login_context/LoginContext';
import { GetData_ExtPozo } from '../../../helper/GetData_ExtPozo.api';
import { EditOldDataOCHA } from '../../../components/EditOldDataOCHA';
import { FormatNumeral } from '../../../components/FormatNumber';
import { AddnewDato } from '../../../components/AddnewDato';
import { DeleteDataById } from '../../../helper/DeleteDataById.api';
import { AddnewDatoOCHA } from '../../../components/AddnewDatoOCHA';
import { AddnewDatoArica } from '../../../components/AddnewDatoArica';
import { EditOldDataArica } from '../../../components/EditOldDataArica';

export const DatosPageArica = ({IndicadorEconomico,Ref_Brasil}) => {
  // console.log(IndicadorEconomico,Ref_Brasil)
  const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [DataList, setDataList] = useState([])
  const [IsReload, setIsReload] = useState(1)

  const GetDataList = async () => {
    let getList = await GetData_ExtPozo(LoginState.planta)
    setDataList(getList)
    // console.log(getList)
  }

  useEffect(() => {
    GetDataList()
  }, [])

  useEffect(() => {
    GetDataList()
  }, [IsReload])

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - DataList.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const DeleteeDataConfirm = async (id, fecha) => {
    // console.log(id, fecha)
    let seBorra = window.confirm(`Esta seguro de eliminar el dato con fecha ${fecha}`)
    if(seBorra){
      let respuesta = await DeleteDataById({id})
      if(respuesta){
        alert('Data Eliminada')
        setIsReload(IsReload+1)
      }
    }
  }
  return (
    <>
      <HeaderMenu Pag_Sel={'datos'} />

      <div className='container'>
        <div className='row'>
          <div className='col'>
            <AddnewDatoArica IsReload={IsReload} setIsReload={setIsReload}
                UF={IndicadorEconomico.uf.valor}
                DOLAR={IndicadorEconomico.dolar.valor}/>
          </div>
        </div>
      </div>

      <div className='d-flex justify-content-center'>
        {
          DataList?
          <Paper sx={{ width: '98%', height: '75vh', overflow: '' }}>
          <TableContainer sx={{ maxHeight: '67vh' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}} size='small'>FECHA</TableCell>
                  <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>TOTAL CONSUMO AGUA</TableCell>
                  <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>N° POLLO</TableCell>
                  <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>PESO POLLO</TableCell>
                  <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>TOTAL POLLO</TableCell>
                  <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>N° GALLINA</TableCell>
                  <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>PESO GALLINA</TableCell>
                  <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>TOTAL GALLINA</TableCell>
                  <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>UE</TableCell>
                  <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>L/UE</TableCell>
                  <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>L/POLLO</TableCell>
                  <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>L/KG VIVO</TableCell>
                  <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>M³ AGUA PTR</TableCell>
                  <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>M³ AGUA ASEO</TableCell>
                  <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}>COSTO</TableCell>
                  <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}><EditRoundedIcon /></TableCell>
                  <TableCell style={{fontSize: 'small', padding:'0px 2px', textAlign:'center',background:'black',color:'white',border:'1px solid white'}}><DeleteForeverIcon /></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {(rowsPerPage > 0
                ? DataList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : DataList
              ).map((row) =>
                <TableRow
                  key={row._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  className='hoverTable'
                >
                  <TableCell style={{textAlign:'center',padding:'2px 0px',border:'1px solid black'}}>{new Date(row.FECHA).toISOString().split('T',1)}</TableCell>
                  <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(row.TOTAL.toFixed(1))}</TableCell>
                  <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(row.N_POLLO)}</TableCell>
                  <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(row.PESO_POLLO.toFixed(1))}</TableCell>
                  <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(row.PESO_TOTAL_POLLO.toFixed(1))}</TableCell>
                  <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(row.N_PAVO)}</TableCell>
                  <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(row.PESO_PAVO.toFixed(1))}</TableCell>
                  <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(row.PESO_TOTAL_PAVO.toFixed(1))}</TableCell>
                  <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(row.UE.toFixed(1))}</TableCell>
                  <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(Number(row['L/UE']).toFixed(1))}</TableCell>
                  <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(Number(row['L/POLLO']).toFixed(1))}</TableCell>
                  <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(Number(row['L/KG_VIVO']).toFixed(1))}</TableCell>
                  <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(Number(row['AGUA_TRATADA']).toFixed(1))}</TableCell>
                  <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(Number(row['AGUA_ASEO']).toFixed(1))}</TableCell>
                  <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>{FormatNumeral(Number(row['COSTO']).toFixed(1))}</TableCell>
                  <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>
                    {
                      LoginState.user!=="Cuenta Demo Vista"?(
                        <EditOldDataArica id={row._id}
                          IsReload={IsReload}
                          setIsReload={setIsReload}
                          UF={IndicadorEconomico.uf.valor}
                          DOLAR={IndicadorEconomico.dolar.valor}
                        />
                      ):null
                    }
                  </TableCell>
                  <TableCell style={{textAlign:"center",padding:'2px 0px',border:'1px solid black'}}>
                    {
                      LoginState.user!=="Cuenta Demo Vista"?(<span onClick={()=>DeleteeDataConfirm(row._id, new Date(row.FECHA).toISOString().split('T',1))}><DeleteForeverIcon color='error'/></span>):null
                    }
                  </TableCell>
                </TableRow>
              )}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[ 25, 50, 75, 100]}
            component="div"
            count={DataList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        :null
        }
      </div>
      <div className='container-fluid footer-bar mt-2 fondo-header header-text'>
        <div className='row'>
          <div className='col-12 small-font text-center pt-1'>
            Valor UF: $ <b>{IndicadorEconomico?FormatNumeral(IndicadorEconomico.uf.valor):'Cargando'}</b><span className='px-2'></span>
            Valor DOLAR: <b>US {IndicadorEconomico?FormatNumeral(IndicadorEconomico.dolar.valor):'Cargando'}</b><span className='px-2'></span>
            Top 25 Brasil: <b>{Ref_Brasil?FormatNumeral(Ref_Brasil.TOP_BRASIL):'Cargando'}</b> L/UE<span className='px-2'></span>
            Promedio Brasil: <b>{Ref_Brasil?FormatNumeral(Ref_Brasil.PRO_BRASIL):'Cargando'}</b> L/UE<span className='px-3'></span>
            Valores actualizados <b>{IndicadorEconomico?IndicadorEconomico.fecha.split('T',1):'Cargando'}</b>
          </div>
        </div>
      </div>
      
    </>
  )
}
