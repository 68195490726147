import React, { useState } from 'react'
import { Button, FormControl, Input, InputAdornment, InputLabel } from '@mui/material'
import AlternateEmailTwoToneIcon from '@mui/icons-material/AlternateEmailTwoTone';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import ReplyAllOutlinedIcon from '@mui/icons-material/ReplyAllOutlined';
import { ForgetPass } from '../../helper/ForgetPass.api';
import { useNavigate } from 'react-router-dom';

const RecoveryPass = () => {
  const navigate = useNavigate();
  const [valores, setValores] = useState({
    email:'',
    isSubmit: false,
    errorMessage: null
  })

  const HandleSetData = async (e) => {
    setValores({...valores,[e.target.name]:e.target.value.trim()})
  }

  const HandleSubmitForget = async () => {
    const forgetPass = await ForgetPass(valores.email)
    if(forgetPass.success){
      navigate('../')
    }else{
      setValores({...valores,errorMessage:forgetPass.error})
    }
  }
  return (
    <>
    <div className="container">
    <div className="row" style={{height: '100vh'}}>
      <div className="col-12 col-md-6 d-flex align-items-center text-center justify-content-center">
        <img src="/img/logo_ariztia.png" alt="Ariztia" />
      </div>
      <div className="col-12 col-md-6 px-3 fondo d-flex align-items-center text-center justify-content-center bsdw-login " >
        <div className="row">
          <div className="col-12 pt-3 text-left">
            <h4>Recupera Tu Clave</h4>
          </div>
          <div className="col-12 pt-3">
            <FormControl fullWidth variant="standard">
              <InputLabel htmlFor="input-username">
                Correo Electronico
              </InputLabel>
              <Input
                type="email"
                name='email'
                value={valores.email}
                onChange={(e)=>HandleSetData(e)}
                startAdornment={
                  <InputAdornment position="start">
                    <AlternateEmailTwoToneIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          <div className="col-12">
            {valores.errorMessage?
            <small className="text-danger">{valores.errorMessage}</small>
            :null
            }
          </div>
          <div className="col-12 pt-5 text-right">
            <Button
              fullWidth
              variant="contained"
              size="small"
              onClick={()=>HandleSubmitForget()}
              endIcon={<SendTwoToneIcon />} >Recuperar clave</Button>
          </div>
          <div className='col-12 pt-5'>
            <Button
              variant='outlined'
              onClick={()=>navigate('../')}
            >
              <ReplyAllOutlinedIcon /> <span className='pl-3'></span>Volver
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </>
  )
}

export default RecoveryPass