import { URL_CTRL_HIDRIC } from '../router/Url';

export const GetRefBrasil = () => {
  return new Promise(async function(resolve, reject) {
    try {
      const query = await fetch(URL_CTRL_HIDRIC+`getref_brasil`)

        const reqkkst = await query.json();
        // console.log('el BRAZILLL=>>>>>>>',reqkkst.body)
        if(reqkkst){
          resolve(reqkkst.body)
        }else{
          resolve(null)
        }
      } catch (error) {
        resolve(null)
      }
  })
}
