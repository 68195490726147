import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Drawer } from '@mui/material';
import LoginContext from '../context/login_context/LoginContext';
import { MakeFileAllData } from '../helper/MakeFileAllData.api';

import MenuIcon from '@mui/icons-material/Menu';
import OutputOutlinedIcon from '@mui/icons-material/OutputOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';

export const HeaderMenu = ({Pag_Sel}) => {
  const navigate = useNavigate();
  const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
  const [PageSelcected, setPageSelcected] = useState(Pag_Sel)
  // console.log(LoginState.user)

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const HandleCloseSession = async () => {
    CloseLogin()
    navigate('/')
  }

  const Make_A_File_whit_all_Data = async () => {
    
    await MakeFileAllData(LoginState.planta)
  }

  return (
    <>
      <div className='container-fluid fondo-header header-text mb-2'>
        <div className='row'>
          <div className='col-md-3 hidden-xs'>
            <img src='favicon.png' height={'50px'}/>
          </div>
          <div className='col-12 col-md-9 p-auto m-auto'>
            <div className='row'>
              <div className='col-10'>
                <div className='row'>
                  <div className='col-12 col-md-6'>
                    <b>{LoginState.planta}</b>
                  </div>
                  <div className='col-12 col-md-6 text-right'>
                    {LoginState.user}
                  </div>
                </div>
              </div>
              <div className='col-2 text-right  p-auto m-auto'>
                <span className='menubtn' onClick={toggleDrawer('right', true)}><MenuIcon/></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Drawer
        anchor={'right'}
        open={state['right']}
        onClose={toggleDrawer('right', false)}
      >
        <div className='menu-container'>
            <img src='logo512.png' style={{height: "150px"}} className='px-5' />
            <hr/>
            <br/>
            <div className='' onClick={toggleDrawer('right', false)}>
              <p className={PageSelcected==='dashboard'?'menu-item selected-item':'menu-item'} onClick={()=>navigate('/dashboard')}><CottageOutlinedIcon className='color-icon-menu'/><span className='spacing'></span> Principal</p>
            </div>
            {
              
              LoginState.user!=="Cuenta Demo Vista"?(LoginState.planta==="AGROINDUSTRIAL EL PAICO S.A."?<div className='' onClick={toggleDrawer('right', false)}>
              <p className={PageSelcected==='carga_archivo'?'menu-item selected-item':'menu-item'} onClick={()=>navigate('/carga_archivo')}><UploadFileOutlinedIcon className='color-icon-menu'/><span className='spacing'></span> Cargar Archivo</p>
            </div>:null)
            :null
            }
            
            <div className='' onClick={toggleDrawer('right', false)}>
              <p className={PageSelcected==='datos'?'menu-item selected-item':'menu-item'} onClick={()=>navigate('/datos')}><FormatListNumberedOutlinedIcon className='color-icon-menu'/><span className='spacing'></span> Datos</p>
            </div>
            <div className='' onClick={toggleDrawer('right', false)}>
              <p className='menu-item' onClick={()=>Make_A_File_whit_all_Data()}><MoveToInboxIcon className='color-icon-menu'/><span className='spacing'></span> Descargar Reporte</p>
            </div>
            <br/>
            <hr/>
            <p className='menu-item' onClick={()=>HandleCloseSession()}>
              <OutputOutlinedIcon className='color-icon-menu'/><span className='spacing'></span> Salir
            </p>
            <div className='menu-footer-version'>
              <small>version 1.0.3-b</small>
            </div>
        </div>
      </Drawer>

    </>
  )
}
