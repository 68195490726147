import React, { useContext, useEffect, useState } from 'react'
import { HeaderMenu } from '../../../components/HeaderMenu'
import LoginContext from '../../../context/login_context/LoginContext';
import { GetLastData } from '../../../helper/GetLastData.api'

export const DashBoardArica = () => {
  const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
  const [LastData_, setLastData_] = useState('')

  const GetLasInsertData = async () => {
    const lastData = await GetLastData(LoginState.planta)
    setLastData_(lastData)
  }

  useEffect(() => {
    GetLasInsertData()
  }, [])
  return (
    <>
      <HeaderMenu />
        <div className='container pt-3'>
          <div className='row'>
            <div className='col'>
              <span className='bg-danger p-2'
                style={{borderRadius:'5px', color: 'yellow', fontWeight: 'unset'}}
              > {LastData_?`Fecha Ultimo dato Ingresado ${LastData_}`:"No Hay Datos Ingresados aun 😐"}</span>
            </div>
          </div>
        </div>
    </>
  )
}
